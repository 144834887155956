var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "c-section" },
      [
        _c("h1", { staticClass: "c-title2 c-title2--mb16" }, [
          _vm._v(" オンラインストアとの連携が完了しました "),
        ]),
        _c("p", { staticClass: "p-contact__thanks" }, [
          _vm._v(
            " アプリとオンラインストアの連携が完了しました。アプリを使ってお得にお買い物を楽しみましょう。 "
          ),
        ]),
        _c("div", { staticClass: "p-linkOnlineStore__userInfo" }, [
          _c("div", { staticClass: "p-linkOnlineStore__userInfo__item" }, [
            _c("div", { staticClass: "c-title6 c-title6--mb4" }, [
              _vm._v("氏名"),
            ]),
            _c("div", [_vm._v("姓：" + _vm._s(_vm.user.lastName))]),
            _c("div", [_vm._v("名：" + _vm._s(_vm.user.firstName))]),
          ]),
          _c("div", { staticClass: "p-linkOnlineStore__userInfo__item" }, [
            _c("div", { staticClass: "c-title6 c-title6--mb4" }, [
              _vm._v("オンラインストアID"),
            ]),
            _c("div", [_vm._v(_vm._s(_vm.user.id))]),
          ]),
        ]),
        _c(
          "Button",
          {
            staticClass: "p-linkOnlineStore__action",
            attrs: {
              href: _vm.$customUrlScheme.page("root/home/1"),
              "style-type": "tertiary",
              fluid: "",
              flat: "",
            },
          },
          [_vm._v(" トップへ戻る ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }